<template>
  <el-dialog
    :visible.sync="$store.state.globalShare.addFriendPopupStatus"
    custom-class="addFriendDialog"
    @close="close"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <label class="info-box">
      <img class="avatar" :src="formartImageUrl(popUpdata.avatar)" alt />
      <span :class="popUpdata.vipStatus ? 'vipName' : ''">
        {{ _.truncate(popUpdata.name, { length: 13 }) }}
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('paidMember')"
          placement="bottom"
          popper-class="me-tooltip-effect"
        >
          <svg
            v-if="popUpdata.vipStatus"
            style="cursor:pointer;margin-left: 4px;"
            @click.stop="handleClickVip(popUpdata.id)"
            t="1665478837117"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="8842"
            width="20"
            height="20"
          >
            <path
              d="M659.328 149.333333H365.482667a125.141333 125.141333 0 0 0-89.386667 37.888L73.813333 394.410667a50.261333 50.261333 0 0 0 0 69.802666l393.386667 402.730667a62.890667 62.890667 0 0 0 90.325333 0l393.386667-402.730667a50.261333 50.261333 0 0 0 0-69.802666l-202.410667-207.189334a124.757333 124.757333 0 0 0-89.173333-37.888z"
              fill="#0DCEA7"
              p-id="8843"
            ></path>
            <path
              d="M73.813333 394.410667a50.261333 50.261333 0 0 0 0 69.802666l8.533334 8.533334a413.866667 413.866667 0 0 1 20.522666-108.16z m877.141334 0l-99.456-101.845334a409.6 409.6 0 0 1 53.333333 202.794667q0 8.192-0.298667 16.341333l46.421334-47.530666a50.261333 50.261333 0 0 0 0.042666-69.76z"
              fill="#0DCEA7"
              p-id="8844"
            ></path>
            <path
              d="M168.746667 297.216L102.826667 364.672a409.344 409.344 0 0 0-20.522667 108.16l40.32 41.258667a355.84 355.84 0 0 1 46.165333-216.917334z m682.666666-4.693333l-102.912-105.344a124.8 124.8 0 0 0-89.386666-37.888h-43.690667a355.370667 355.370667 0 0 1 186.410667 467.285333l102.4-105.045333q0.298667-8.192 0.298666-16.341334a408.448 408.448 0 0 0-53.034666-202.666666z m-422.016 535.722666l37.76 38.656a62.890667 62.890667 0 0 0 90.325334 0l66.474666-68.053333a354.56 354.56 0 0 1-148.266666 32.341333 363.178667 363.178667 0 0 1-46.208-2.901333z"
              fill="#1DD49C"
              p-id="8845"
            ></path>
            <path
              d="M615.509333 149.333333H365.610667a125.141333 125.141333 0 0 0-89.386667 37.888L168.832 297.216a355.84 355.84 0 0 0-46.165333 216.917333l62.592 64a298.325333 298.325333 0 1 1 145.066666 148.650667l99.114667 101.461333a362.666667 362.666667 0 0 0 46.250667 3.029334 353.877333 353.877333 0 0 0 148.266666-32.341334l177.962667-182.144A355.541333 355.541333 0 0 0 615.509333 149.333333z"
              fill="#2DDB92"
              p-id="8846"
            ></path>
            <path
              d="M757.034667 456.576a298.666667 298.666667 0 1 0-572.032 121.6l145.066666 148.650667a299.050667 299.050667 0 0 0 426.666667-270.165334z m-558.933334-19.370667a242.389333 242.389333 0 1 1 242.389334 242.389334 242.432 242.432 0 0 1-242.261334-242.261334z"
              fill="#3DE188"
              p-id="8847"
            ></path>
            <path
              d="M683.050667 437.290667a242.389333 242.389333 0 1 0-242.389334 242.432 242.432 242.432 0 0 0 242.389334-242.432z m-259.925334 166.656a186.026667 186.026667 0 1 1 186.026667-186.026667 186.026667 186.026667 0 0 1-186.026667 186.069333z"
              fill="#4CE77D"
              p-id="8848"
            ></path>
            <path
              d="M423.125333 231.978667a186.026667 186.026667 0 1 0 186.026667 186.026666 186.026667 186.026667 0 0 0-186.026667-186.026666z m-17.536 296.234666a129.578667 129.578667 0 1 1 129.578667-129.578666 129.578667 129.578667 0 0 1-129.536 129.578666z"
              fill="#5CEE73"
              p-id="8849"
            ></path>
            <path
              d="M276.010667 398.634667a129.578667 129.578667 0 1 0 129.578666-129.578667 129.578667 129.578667 0 0 0-129.578666 129.578667z"
              fill="#6CF468"
              p-id="8850"
            ></path>
            <path
              d="M659.328 166.869333a105.002667 105.002667 0 0 1 41.472 8.533334 109.312 109.312 0 0 1 35.328 24.149333l202.410667 207.146667a32.64 32.64 0 0 1 0 45.269333l-393.472 402.688a45.184 45.184 0 0 1-65.237334 0l-393.386666-402.730667a32.64 32.64 0 0 1 0-45.269333L288.853333 199.509333a107.264 107.264 0 0 1 35.328-24.149333 105.002667 105.002667 0 0 1 41.472-8.533333h293.717334m0-17.536H365.482667a125.141333 125.141333 0 0 0-89.386667 37.888L73.813333 394.368a50.261333 50.261333 0 0 0 0 69.802667l393.386667 402.730666a62.805333 62.805333 0 0 0 90.325333 0l393.386667-402.730666a50.261333 50.261333 0 0 0 0-69.802667l-202.410667-207.189333a124.757333 124.757333 0 0 0-89.173333-37.845334z"
              fill="#0EC69A"
              p-id="8851"
            ></path>
            <path
              d="M582.954667 297.429333h-141.141334a60.330667 60.330667 0 0 0-42.922666 18.090667L301.653333 414.421333a23.808 23.808 0 0 0 0 33.28l189.056 192.384a30.378667 30.378667 0 0 0 43.434667 0l189.056-192.384a23.808 23.808 0 0 0 0-33.28l-97.237333-98.901333a60.288 60.288 0 0 0-43.008-18.090667z"
              fill="#9CFFBD"
              p-id="8852"
            ></path>
            <path
              d="M229.12 343.552a17.536 17.536 0 0 1-12.458667-29.866667l85.077334-85.76a17.578667 17.578667 0 1 1 24.917333 24.746667l-85.077333 85.76a17.706667 17.706667 0 0 1-12.458667 5.12zM180.821333 392.234667a17.536 17.536 0 0 1-12.458666-29.866667l7.210666-7.296a17.536 17.536 0 1 1 24.917334 24.704l-7.210667 7.296a17.450667 17.450667 0 0 1-12.458667 5.162667z"
              fill="#FFFFFF"
              p-id="8853"
            ></path>
          </svg>
        </el-tooltip>
      </span>
    </label>
    <el-input type="textarea" v-model="tips"></el-input>
    <el-button
      type="primary"
      round
      icon="el-icon--send"
      @click="submitFunc"
      :loading="loading"
    ></el-button>
  </el-dialog>
</template>
<script>
import { addFriend } from "@/api/newVersion";
export default {
  name: "Add",
  data() {
    return {
      tips: "",
      loading: false,
    };
  },
  computed: {
    popUpdata() {
      return this.$store.state.globalShare.userInfoForAddFriend;
    },
  },
  methods: {
    async submitFunc() {
      this.loading = true;
      let params = {
        senderId: this.$store.getters.userInfo.id,
        targetId: this.$store.state.globalShare.userInfoForAddFriend.id,
        tipCode: 207,
        language: localStorage.getItem("langCode") || "1",
        tipContent: this.tips || "",
      };
      let result = await addFriend(params);
      if (result && result.code === 200) {
        this.$store.dispatch("commitCloseAddFriendPopup");
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
        this.loading = false;
      }
    },
    close() {
      this.$store.dispatch("commitCloseAddFriendPopup");
    },
  },
};
</script>
<style lang="stylus">
.addFriendDialog
  width: 350px !important;
  border-radius: 5px !important;
  margin-top 0 !important;
  .el-dialog__header
    .el-dialog__headerbtn
      i
        font-size: 1.5rem !important;
  .el-dialog__body
    padding: 0 20px !important;
    .el-textarea
      min-height: 100px !important;
      .el-textarea__inner
        min-height: 100px !important;
    .el-button
      padding: 0.25rem 2.5rem !important;
      margin: 0.9rem 0 !important;
      float: right !important;
      i
        font-size: 1.5rem !important;
        color: #ffffff !important;
  .info-box
    display: flex !important;
    flex-flow: row !important;
    align-items: center !important;
    margin: 0.5rem 0 !important;
    .avatar
      width: 50px !important;
      height: 50px !important;
      border-radius: 50px !important;
      margin-right: 0.5rem !important;
    span
      margin: 0 !important;
      color: #33cc66 !important;
</style>
